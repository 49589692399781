import axios from 'axios';

const API = axios.create({ baseURL: 'https://api.gujjustream.com/api/' });
export const IMAGE = 'https://api.gujjustream.com/'

// const API = axios.create({ baseURL: 'http://164.52.212.231:7533/api/' });
// export const IMAGE = 'http://164.52.212.231:7533/'






// Master->Category
export const category_create = (formData) => API.post('/category_create/', formData);
export const category_update = (formData) => API.post('/category_update/', formData);
export const category_delete = (formData) => API.post('/category_delete/', formData);
export const all_category_list = (formData) => API.post('/all_category_list/', formData);


// Master->Sub Category
export const subcategory_create = (formData) => API.post('/subcategory_create/', formData);
export const subcategory_update = (formData) => API.post('/subcategory_update/', formData);
export const subcategory_delete = (formData) => API.post('/subcategory_delete/', formData);
export const all_subcategory_list = (formData) => API.post('/all_subcategory_list/', formData);


// Master->Cast
export const cast_create = (formData) => API.post('/cast_create/', formData);
export const cast_update = (formData) => API.post('/cast_update/', formData);
export const cast_delete = (formData) => API.post('/cast_delete/', formData);
export const all_cast_list = (formData) => API.post('/all_cast_list/', formData);

// Master->Country

export const all_country_list = (formData) => API.post('/all_country_list/', formData);

// Master->Qr

export const qr_create = (formData) => API.post('/qr_create/', formData);
export const qr_delete = (formData) => API.post('/qr_delete/', formData);
export const all_qr_list = (formData) => API.post('/all_qr_list/', formData);


// Need to add this APIs
// path('api/cast_details/',views.cast_details, name='cast_details'),

export const proxy_payouts = (formData) => API.post('/proxy_payouts/' ,formData)

// Promotion 

export const quick_promotion_create = (formData) => API.post('/quick_promotion_create/' ,formData)
export const all_quick_promotion_list = (formData) => API.post('/all_quick_promotion_list/' , formData)
export const quick_promotion_update = (formData) => API.post('/quick_promotion_update/' , formData)
export const quick_promotion_delete = (formData) => API.post('/quick_promotion_delete/' , formData)

// Promotion 

export const highlight_create = (formData) => API.post('/highlight_create/' ,formData)
export const all_highlight_list = (formData) => API.post('/all_highlight_list/' , formData)
export const highlight_update = (formData) => API.post('/highlight_update/' , formData)
export const highlight_delete = (formData) => API.post('/highlight_delete/' , formData)

// Producer Form

export const producer_movie_form_create = (formData) => API.post('/producer_movie_form_create/' , formData)
export const all_producer_movie_form_list = (formData) => API.post('/all_producer_movie_form_list/' , formData)
export const producer_movie_form_update = (formData) => API.post('/producer_movie_form_update/' , formData)
export const producer_movie_detail_form = (formData) => API.post('/producer_movie_detail_form/' , formData)


export const producer_create = (formData) => API.post('/producer_register/', formData); // Pending
export const producer_update = (formData) => API.post('/producer_update/', formData); // Pending
export const producer_delete = (formData) => API.post('/producer_delete/', formData); // Pending
export const all_producer_list = (formData) => API.post('/all_producer_list/', formData); 

// Reports

export const report_data = (formData) => API.post("/report_data/" , formData)


// Master->Genre
export const genre_create = (formData) => API.post('/genre_create/', formData);
export const genre_update = (formData) => API.post('/genre_update/', formData);
export const genre_delete = (formData) => API.post('/genre_delete/', formData);
export const all_genre_list = (formData) => API.post('/all_genre_list/', formData);

// Master -> Content Advisory
export const content_advisory_create = (formData) => API.post('/content_advisory_create/' , formData);
export const all_content_advisory_list = (formData) => API.post('/all_content_advisory_list/' , formData); 
export const content_advisory_delete = (formData) => API.post("/content_advisory_delete/",formData);

// Master->Language
export const language_create = (formData) => API.post('/language_create/', formData);
export const language_update = (formData) => API.post('/language_update/', formData);
export const language_delete = (formData) => API.post('/language_delete/', formData);
export const all_language_list = (formData) => API.post('/all_language_list/', formData);

// Master->Distributor
export const distributor_create = (formData) => API.post('/distributor_register/', formData); // Pending
export const distributor_update = (formData) => API.post('/distributor_update/', formData); // Pending
export const distributor_delete = (formData) => API.post('/distributor_delete/', formData); // Pending
export const all_distributor_list = (formData) => API.post('/all_distributor_list/', formData); 

// Master->Advertisor
export const advertiser_create = (formData) => API.post('/advertiser_register/', formData); // Pending
export const advertiser_update = (formData) => API.post('/advertiser_update/', formData); // Pending
export const advertiser_delete = (formData) => API.post('/advertiser_delete/', formData); // Pending
export const all_advertiser_list = (formData) => API.post('/all_advertiser_list/', formData); 
export const advertise_transaction_create = (formData) => API.post('/advertise_transaction_create/' , formData)
export const advertiser_transaction_history = (formData) => API.post("/advertiser_transaction_history/" , formData);
export const advertise_form_create = (formData) => API.post('/advertise_form_create/' , formData)
export const all_advertise_form_list = (formData) => API.post('/all_advertise_form_list/',formData)
export const advertise_form_upate = (formData) =>API.post('/advertise_form_upate/' , formData)
export const advertise_form_delete = (formData) => API.post('/advertise_form_delete/' , formData)
export const all_advertise_transaction_list = (formData) => API.post('/all_advertise_transaction_list/' , formData)
// export const advertise_transaction_create =(formData) => API.post('/advertise_transaction_create/',formData)

// Master->Sub Admin
export const subadmin_create = (formData) => API.post('/sub_admin_register/', formData); // Pending
export const subadmin_update = (formData) => API.post('/sub_admin_update/', formData); // Pending
export const subadmin_delete = (formData) => API.post('/sub_admin_delete/', formData); // Pending
export const all_subadmin_list = (formData) => API.post('/all_sub_admin_list/', formData); // Pending
export const all_module_listing = (formData) => API.post('/all_module_listing/', formData);

// Live Streaming->TV Category
export const tv_category_create = (formData) => API.post('/live_stream_category_create/', formData); 
export const tv_category_update = (formData) => API.post('/live_stream_category_update/', formData); 
export const tv_category_delete = (formData) => API.post('/live_stream_category_delete/', formData); 
export const all_tv_category_list = (formData) => API.post('/all_live_stream_category_list/', formData); 

// Live Streaming->TV Channel
export const tv_channel_create = (formData) => API.post('/live_stream_create/', formData); 
export const tv_channel_update = (formData) => API.post('/live_stream_update/', formData); 
export const tv_channel_delete = (formData) => API.post('/live_stream_delete/', formData); 
export const all_tv_channel_list = (formData) => API.post('/all_live_stream_list_admin/', formData); 
// Need to add this APIs
// path('api/live_stream_details/',views.live_stream_details, name='live_stream_details'),
 

// Merchandise->Coupon
export const coupon_create = (formData) => API.post('/coupon_create/', formData);
export const coupon_update = (formData) => API.post('/coupon_update/', formData);
export const coupon_delete = (formData) => API.post('/coupon_delete/', formData); 
export const all_coupon_list = (formData) => API.post('/all_coupon_list/', formData);
export const coupon_details= (formData) => API.post('/coupon_details/', formData);

// Notification
export const notification_create = (formData) => API.post('/notification_create/', formData);
export const notification_update = (formData) => API.post('/notification_update/', formData);
export const notification_delete = (formData) => API.post('/notification_delete/', formData);
export const all_notification_list = (formData) => API.post('/all_notification_list/', formData);
export const notification_read_status_update = (formData) => API.post('/notification_read_status_update/', formData);
export const notification_unread_count = (formData) => API.post('/notification_unread_count/', formData);


// Bank Details

export const get_bank_detail = (formData) => API.post('/get_bank_detail/' , formData);
export const add_bank_account = (formData) => API.post('/add_bank_account/' , formData);
export const bank_detail_list = (formData) => API.post('/bank_detail_list/' , formData);


// Curren
export const live_movie_view_list = (formData) => API.post('/live_movie_view_list/',formData);


// Merchandise->Slider Banner
export const sliderbanner_create = (formData) => API.post('/slider_banner_create/', formData);
export const sliderbanner_update = (formData) => API.post('/slider_banner_update/', formData);
export const sliderbanner_delete = (formData) => API.post('/slider_banner_delete/', formData);
export const all_sliderbanner_list = (formData) => API.post('/all_slide_banner_list/', formData);
export const intro_data_create = (formData) => API.post('/intro_data_create/', formData);
export const all_intro_data_list = (formData) => API.post('/all_intro_data_list/' ,formData);
export const intro_data_update = (formData) => API.post('/intro_data_update/',formData)
export const intro_data_delete = (formData) => API.post('/intro_data_delete/' ,formData)
// Need to add API
// path('api/slider_details/',views.slider_details, name='slider_details'),


// Movie->Movie
export const movie_create = (formData) => API.post('/movie_create/', formData);
export const movie_update = (formData) => API.post('/movie_update/', formData);
export const movie_delete = (formData) => API.post('/movie_delete/', formData);
export const all_movie_list = (formData) => API.post('/all_movie_list_admin/', formData);
export const movie_details = (formData) => API.post('/movie_details_for_admin/', formData);
export const movie_watch_user_list = (formData) => API.post('/movie_watch_user_list/', formData);
export const subtitle_delete = (formData) => API.post("/subtitle_delete/" ,formData)
export const audio_file_delete = (formData) => API.post("/audio_file_delete/" ,formData)
export const qr_delete_from_movie = (formData) => API.post('/qr_delete_from_movie/' , formData)
export const all_movie_name_list = (formData) => API.post('/all_movie_name_list/' , formData)



// Movie->Upcoming Movie
export const upcoming_movie_create = (formData) => API.post('/upcoming_movie_create/', formData);
export const upcoming_movie_update = (formData) => API.post('/upcoming_movie_update/', formData);
export const upcoming_movie_delete = (formData) => API.post('/upcoming_movie_delete/', formData); 
export const all_upcoming_movie_list = (formData) => API.post('/all_upcoming_movie_list/', formData);

// Need to add API
// path('api/upcoming_movie_details/',views.upcoming_movie_details, name='upcoming_movie_details'),


// Report->PPV Customer
export const all_ppv_customer_list = (formData) => API.post('/all_subadmin_list/', formData); // Pending

// Report->Registered Customer
export const all_registered_customer_list = (formData) => API.post('/all_subadmin_list/', formData); // Pending

// Report->Registered Customer
export const all_subscribed_customer_list = (formData) => API.post('/all_subadmin_list/', formData); // Pending


// Web Series->Episode
export const episode_create = (formData) => API.post('/episode_create/', formData);
export const episode_update = (formData) => API.post('/episode_update/', formData);
export const episode_delete = (formData) => API.post('/episode_delete/', formData);
export const all_episode_list = (formData) => API.post('/all_episode_list/', formData);
export const episode_details= (formData) => API.post('/episode_details_admin/', formData); // rather then this API we need to user episode_list_by_series
export const episode_watch_user_list= (formData) => API.post('/episode_watch_user_list/', formData);
export const episode_audio_file_delete = (formData) =>API.post('/episode_audio_file_delete/',formData)
export const episode_subtitle_delete = (formData) =>API.post('/episode_subtitle_delete/',formData)


// Need to add API
// path('api/episode_details/',views.episode_details, name='episode_details'),
// path('api/episode_list_by_series/',views.episode_list_by_series, name='episode_list_by_series'),


// Web Series->Series
export const series_create = (formData) => API.post('/series_create/', formData);
export const series_update = (formData) => API.post('/series_update/', formData);
export const series_delete = (formData) => API.post('/series_delete/', formData);
export const all_series_list = (formData) => API.post('/all_series_list/', formData);
export const series_details_admin = (formData) => API.post('/series_details_admin/' , formData)

// Web Series->Season
export const season_create = (formData) => API.post('/season_create/', formData);
export const season_update = (formData) => API.post('/season_update/', formData);
export const season_delete = (formData) => API.post('/season_delete/', formData);
export const all_season_list = (formData) => API.post('/all_season_list/', formData);
// Need to add API
// path('api/series_details/',views.series_details, name='series_details'),


// Advertisement
export const advertisement_create = (formData) => API.post('/advertise_create/', formData);
export const advertisement_update = (formData) => API.post('/advertise_update/', formData);
export const advertisement_delete = (formData) => API.post('/advertise_delete/', formData);
export const all_advertisement_list = (formData) => API.post('/all_advertise_list/', formData);
export const set_advertise = (formData) => API.post('/set_advertise/',formData)
export const set_advertise_list = (formData) =>API.post('/set_advertise_list/' , formData);
export const set_update_advertise = (formData) => API.post('/set_update_advertise/' ,formData);
export const set_advertise_delete = (formData) => API.post('/set_advertise_delete/',formData)


// Customer
export const customer_update = (formData) => API.post('/user_status_update/', formData); // Pending
export const customer_delete = (formData) => API.post('/customer_delete/', formData); // Pending
export const all_customer_list = (formData) => API.post('/customer_list/', formData); // Pending
export const customer_details = (formData) => API.post('/customer_details/', formData); // Pending
export const highest_plan_buy_count = (formData) => API.post('/highest_plan_buy_count/',formData)
export const highest_rented_movie_count = (formData) => API.post('/highest_rented_movie_count/',formData)


// Subscription
export const subscription_create = (formData) => API.post('/subscription_create/', formData);
export const subscription_update = (formData) => API.post('/subscription_update/', formData);
export const subscription_delete = (formData) => API.post('/subscription_delete/', formData);
export const all_subscription_list = (formData) => API.post('/all_subscription_details/', formData);
// Need to add API
// path('api/subscription_details/',views.subscription_details, name='subscription_details'),

// Demand
export const user_demand_content_list =(formData) => API.post('/user_demand_content_list/' ,formData)
export const user_demand_content_notifications =(formData) => API.post('/user_demand_content_notifications/' ,formData)

// Transaction
export const all_transaction_list = (formData) => API.post('/all_successfull_transaction/', formData); // Pending


// Watch Hours
export const all_watchhour_list = (formData) => API.post('/all_watch_hours_list/', formData); // Pending

// Complaints
export const all_complaint_list = (formData) => API.post('/all_complaint_list/', formData); // Pending
export const complaint_status_update = (formData) => API.post('/complaint_status_update/', formData); // Pending




// Setting->Avatar
export const avatar_create = (formData) => API.post('/avatar_create/', formData);
export const avatar_update = (formData) => API.post('/avatar_update/', formData);
export const avatar_delete = (formData) => API.post('/avatar_delete/', formData);
export const all_avatar_list = (formData) => API.post('/all_avatar_list/', formData);

// Dashboard
export const movies_data_dashboard = (formData) => API.post('/movies_data_dashboard/', formData);
export const count_on_dashboard = (formData) => API.post('/count_on_dashboard/', formData);
export const total_user = (formData) => API.post('/total_user/', formData);
export const total_subscribe_user = (formData) => API.post('/total_subscribe_user/', formData);
export const monthly_revenue = (formData) => API.post('/monthly_revenue/', formData);
export const daily_revenue = (formData) => API.post('/daily_revenue/', formData);
export const total_revenue = (formData) => API.post('/tvod_revenue/', formData);
export const most_watch_tv_show_dashboard = (formData) => API.post('/most_watch_tv_show_dashboard/', formData);
export const recent_subscriber_dashboard = (formData) => API.post('/recent_subscriber_dashboard/', formData);
export const max_login_duration = (formData) => API.post('/max_login_duration/', formData);
export const renewal = (formData) => API.post('/renewal/', formData);
export const producer_movie_graph = (formData) => API.post('/producer_dashboard/', formData);
export const producer_movie_list = (formData) => API.post('/producer_dashboard_movie_listing/', formData);
export const advertisement_view_graph = (formData) => API.post('/dashboard_advertisement_graph/', formData);
export const region_chart = (formData) => API.post('/region_chart/', formData);



export const highest_movie_transaction_count = (formData) => API.post('/highest_movie_transaction_count/' , formData)
export const total_revenue_distribtor = (formData) => API.post('/distribtor_tvod_revenue/', formData);

//Authenticate
export const login = (formData) => API.post('/web_login/', formData);
export const forgot_password = (formData) => API.post('/forgot_password_send_otp/', formData);
export const change_password = (formData) => API.post('/change_password/', formData);
export const verify_otp = (formData) => API.post('/forgot_password_verify_otp/' ,formData)

//Payment 
export const distributor_producer_transaction = (formData) => API.post('/distributor_producer_transaction/' ,formData)

//Setting-> About us
export const about_us = (formData) => API.post('/about_us/', formData);
export const about_us_update = (formData) => API.post('/about_us_update/', formData);

//Setting-> Refund Policy
export const refund_policy = (formData) => API.post('/refund_policy/', formData);
export const refund_policy_update = (formData) => API.post('/refund_policy_update/', formData);

//Setting-> Privacy Policy
export const privacy_policy = (formData) => API.post('/privacy_policy/', formData);
export const privacy_policy_update = (formData) => API.post('/privacy_policy_update/', formData);

//Setting-> Terms And Conditions
export const terms_and_conditions = (formData) => API.post('/terms_and_conditions/', formData);
export const terms_and_conditions_update = (formData) => API.post('/terms_and_conditions_update/', formData);

//Setting-> App Seetting
export const app_setting = (formData) => API.post('/app_setting/', formData);
export const app_setting_update = (formData) => API.post('/app_setting_update/', formData);

//Setting-> User Logs
export const user_log_list = (formData) => API.post('/user_log_list/', formData);
export const user_log_detail = (formData) => API.post('/user_log_details/', formData);


//import
export const bulk_import = (formData) => API.post('/bulk_import/', formData);


//analytics
export const highest_watched_movies_graph = (formData) => API.post('/highest_watched_movies_graph/', formData);
export const lowest_watched_movies = (formData) => API.post('/lowest_watched_movies/', formData);
export const highest_searched_movies_graph = (formData) => API.post('/highest_searched_movies_graph/', formData);
export const currently_logged_in_users = (formData) => API.post('/currently_logged_in_users/', formData);
export const currently_logged_in_users_by_state_graph = (formData) => API.post('/currently_logged_in_users_by_state_graph/', formData);
export const currently_watching_users = (formData) => API.post('/currently_watching_users/', formData);
export const device_used_for_watching = (formData) => API.post('/device_used_for_watching/', formData);
export const area_wise_ad_view = (formData) => API.post('/area_wise_ad_view/', formData);
export const user_highest_earned_coins = (formData) => API.post('/user_highest_earned_coins/', formData);
export const user_watch_count_graph = (formData) => API.post('/user_watch_count_graph/', formData);

